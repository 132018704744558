<template>
    <div>
      <h2>ApiWars</h2>
      <table>
        <thead>
          <tr>
            <th>Название</th>
            <th>Статус</th>
            <th>Завершенность</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(server, index) in servers" :key="index">
            <td>{{ server.name }}</td>
            <td>{{ server.status }}</td>
            <td>{{ server.completion }}%</td>
          </tr>
        </tbody>
      </table>

      <h2>Гвиддон</h2>
      <table>
        <thead>
          <tr>
            <th>Название</th>
            <th>Статус</th>
            <th>Завершенность</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, index) in gvidon" :key="index">
            <td>{{ data.name }}</td>
            <td>{{ data.status }}</td>
            <td>{{ data.completion }}%</td>
          </tr>
        </tbody>
      </table>

      <h2><a href="https://my-cat-point.easy4.team/" target="_blank">HTTP MEOW</a></h2>
     
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue'
  
  const servers = ref([
    { name: 'Альфа', status: '🟢', completion: 65 },
    { name: 'Бетта', status: '🔴', completion: 65 },
    { name: 'Гамма', status: '🔴', completion: 65 },
    { name: 'Сигма', status: '🔴', completion: 65 }
  ])

  const gvidon = ref([
    { name: 'Альфа', status: '🟢', completion: 20 },
    { name: 'Бетта', status: '🔴', completion: 25 }
  ])
  </script>
  
  <style scoped>
  h2 {
    color: #fff;
    margin-bottom: 10px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    color: #ddd;
    background: #222;
  }
  
  th, td {
    border: 1px solid #333;
    padding: 8px;
    text-align: left;
  }
  </style>
  